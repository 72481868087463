import { template as template_9908217cee974c959fe56e5c030b35cb } from "@ember/template-compiler";
const FKText = template_9908217cee974c959fe56e5c030b35cb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
