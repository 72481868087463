import { template as template_f8a1abeb45a24926907d48daa7cef6ce } from "@ember/template-compiler";
const FKControlMenuContainer = template_f8a1abeb45a24926907d48daa7cef6ce(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
