import { template as template_587932055498410fba7e61986c865d66 } from "@ember/template-compiler";
const FKLabel = template_587932055498410fba7e61986c865d66(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
