import { template as template_3d1cb1e8de284fe7a2ff989d50e95576 } from "@ember/template-compiler";
import { get } from "@ember/helper";
import UserFieldConfirm from "./user-fields/confirm";
import UserFieldDropdown from "./user-fields/dropdown";
import UserFieldMultiselect from "./user-fields/multiselect";
import UserFieldText from "./user-fields/text";
const COMPONENTS = {
    confirm: UserFieldConfirm,
    dropdown: UserFieldDropdown,
    multiselect: UserFieldMultiselect,
    text: UserFieldText
};
const UserField = template_3d1cb1e8de284fe7a2ff989d50e95576(`
  {{#let (get COMPONENTS @field.field_type) as |Component|}}
    <Component
      @field={{@field}}
      @value={{@value}}
      @validation={{@validation}}
      ...attributes
    />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserField;
