import { template as template_7d2b42a588594391a49fd874b73a1965 } from "@ember/template-compiler";
const WelcomeHeader = template_7d2b42a588594391a49fd874b73a1965(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
